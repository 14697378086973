import React from "react";
import g from "glamorous";
import Layout from "../../components/layout";
import { css } from "glamor";
import Menu from "../../components/Menu-en";
import Footer from "../../components/Footer-en";
import { Helmet } from "react-helmet";
import { Container } from "glamorous-grid";
import Masonry from "react-masonry-component";
import { Link } from "gatsby";
import { GoQuote } from "react-icons/go";
import { FaHome } from "react-icons/fa";

const Wrapper = g.article({
  maxWidth: `1250px`,
  margin: `0 auto`,
  paddingBottom: `4em`
});

const Title = g.h1({
  textAlign: `center`,
  fontSize: `32px`,
  color: `#3c4d68`,
  margin: `2em auto 1em`,
  fontVariant: `none`,
  fontWeight: `600`,
  letterSpacing: `.01em`,
  lineHeight: `44px`,
  "@media(max-width: 767px)": {
    fontSize: `23px`,
    LineHeight: `31px`
  }
});

const Li = g.li({
  width: `33%`,
  margin: `1em 0 1em`,

  "@media(max-width: 992px)": {
    width: `50%`
  },
  "@media(max-width: 771px)": {
    width: `100%`
  }
});

const Tile = g.section({
  backgroundColor: `white`,
  padding: `0.4em 1em 1em`,
  width: `85%`,
  margin: `0 auto`,
  borderRadius: `4px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`
});

const Testimonial = g.p({});

const Credit = g.div({
  textAlign: `right`,
  fontSize: `12px`
});

const cssQuote = css({
  fontSize: `54px`,
  color: `#3c4d68`,
  display: `block`
});

const masonryOptions = {
  transitionDuration: 0
};

const Breadcrumb = g.ul({
  fontSize: `13px`,
  lineHeight: `20px`,
  marginLeft: `0 !important`,
  marginTop: `0`,
  marginBottom: `10px !important`,
  fontWeight: `500`,
  letterSpacing: `.015em`
});

const BreadcrumbItem = g.li({
  display: `inline`,
  "&:first-of-type": {
    "&::before": {
      content: `none`
    }
  },
  "&::before": {
    content: `/`,
    padding: `0 .5em`
  }
});

const cssBreadcrumbLink = css({
  color: `#3c4d68`,
  borderBottom: `none`
});

const imagesLoadedOptions = { background: ".my-bg-image-el" };

export default () => (
  <Layout>
    <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
      <Helmet>
        <title>Testimonials of patients | Dr Cédric Kron</title>
        <meta
          name="description"
          content="Dr. Cédric Kron's patients share their testimonials, feedback and experience after a cosmetic surgery procedure or aesthetic medicine treatments."
        />
        <html lang="en" />
        <link
          rel="alternate"
          hreflang="fr"
          href="https://www.dr-kron.com/temoignages-avis-patients/"
        />
        <script type="application/ld+json">{` {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Testimonials of patients",
        "item": "https://www.dr-kron.com/en/testimonials/"
        }]
            }
  `}</script>
      </Helmet>
      <Menu current="temoignages" pathFr="/temoignages/" />

      <Wrapper>
        <Container css={{ padding: `0 15px !important` }}>
          <Title>Testimonials of patients</Title>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                <FaHome
                  css={{
                    fontSize: `1.2em`,
                    paddingBottom: `.2em`,
                    transform: `translateY(5px)`
                  }}
                />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>Testimonials of patients</BreadcrumbItem>
          </Breadcrumb>

          <Masonry
            className={"my-gallery-class"} // default ''
            elementType={"ul"} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
            css={{ listStyleType: `none`, margin: `0` }}
          >
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Very satisfied with an operation of cosmetic surgery. The
                  result was up to my expectations and I was confident from the
                  first consultation.
                </Testimonial>
                <Credit>Google Maps Review</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I had a rhinoplasty performed by Dr.
                  Kron a few weeks ago. It had been going on for years, from
                  adolescence to being honest but I was afraid to take the
                  plunge. Fear that it is missed, afraid that it is not natural,
                  afraid that it changes my face ... I even had several
                  consultations with other surgeons, fixing dates of operation,
                  and declining finally the famous final date . The day I met
                  Dr. Kron, I knew immediately that I had no more hesitation to
                  have. I set a date, Dr. Kron was very attentive to my
                  expectations (reduced hump without affecting the rest) and his
                  assistants were adorable and very pleasant to rub shoulders.
                  D-day, I did not really realize, but when he removed the
                  dressing a few days later, I was immensely happy with the
                  result: natural and no change except the decrease in the hump
                  that I had. I wish everyone with this unbearable complex to
                  trust Dr. Kron. He changed my life!
                </Testimonial>
                <Credit>Rhinoplasty | Google Maps Review</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  In addition to his attentive listening and his extreme
                  delicacy, Dr. Kron naturally puts you in confidence. The care
                  provided highlights all its professional qualities. Thank you
                  to you and your team.
                </Testimonial>
                <Credit>Google Maps Review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Very satisfied with the results, and especially a wonderful
                  listening, thank you Dr. Kron for your professionalism, I
                  smile every morning thanks to you.
                </Testimonial>
                <Credit>Facelift LVPA | Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Patient for several years, always very satisfied with the
                  results. I highly recommend Dr. Kron for his professionalism
                  and seriousness. Very attentive to meet the demand.
                </Testimonial>
                <Credit>Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  When I called the office, I was told that I would have 6 short
                  months to wait for an appointment but that did not stop me. On
                  the contrary, if a surgeon is so much asked, it must be of
                  quality, right? It has been a little less than two months
                  since I was operated: a lipo calves and ankles. I am delighted
                  about it. I now put on boots, which I have never been able to
                  do in my life. Dr. Kron is kind and attentive. I have never
                  been afraid, no anxiety about my operation because it knows
                  how to be reassuring, answer all your questions and remain
                  available if necessary. Go there with your eyes closed!
                </Testimonial>
                <Credit>Liposuccion | Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Operated last summer, I am very satisfied with the result of
                  the intervention and the way it went. Dr. Kron was able to
                  listen to me, answer my questions and explain the operation to
                  me. Thanks also to Rihame and Laura who were lovely.
                </Testimonial>
                <Credit>Plastic Surgery | Google Maps review</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />A Surgeon who listens, answers all
                  our questions, gives us confidence from the first
                  consultation, his explanations are clear, he advises us in our
                  choice with a great professionalism. Very good follow up. Very
                  Satisfied with the result, i don't have complexes anymore. I
                  would recommend Dr. Kron to anyone without hesitation.
                </Testimonial>
                <Credit>Pauline | Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I was fortunate to benefit from Dr.
                  Kron's expertise for a double facelift. The result was
                  amazing: presentable after 3 days without problems at work,
                  scars hidden and invisible a month later, the result was
                  further magnified afterwards.
                </Testimonial>
                <Credit>Marie | Facelift</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  It took me several years to make the decision to have a
                  plastic surgery. But when I came across Dr. Kron I no longer
                  doubted for a single second. I wanted to be operated by the
                  best and I found the best. A surgeon who listens and who
                  really advises according to the morphology of his patient. His
                  goal is to have natural results and that's what I was lucky to
                  have. A perfect breast! Pretty ! And very natural! And on top
                  of that I had no pain after the operation! Not to mention the
                  follow-up that was really top with top assistants! Always
                  there to answer any question. I really recommad you to meet
                  him wherever you are! I am from Marseille but the travel is
                  nothing when I see the professionalism of this surgeon.
                </Testimonial>
                <Credit>Marina M. | Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I know Dr. Kron well. Before him
                  another cosmetic surgeon ravaged my face with a failed
                  facelift and bad advice, I was too young to have a face
                  surgery (40 years) ..... I had to bear myself years before it
                  can be remedied. When it was time to retouch my face, Dr. Kron
                  gave me a facelift that although I'm 54 years old makes me
                  feel good and I feel good about myself .... I also took belly
                  (thanks to age) and well it has completely removed by a lipo
                  super effective. I feared the pain I felt almost nothing after
                  and not a mark on the stomach. If people are disappointed by
                  him, I am very surprised, I have total confidence in him, at
                  least with his interventions his advice I am happy and feel
                  good, it is the most important ... Ah yes after the lipo he
                  advised me the sport. In his office there is a fabulous sports
                  coach with a fantastic device that in 20 minutes and the
                  assiduity gives you a nice body, it should be supported by the
                  security. I could not stand in swimsuit, especially because of
                  the other people's eyes, after a few weeks of sport (2x per
                  week) I had a wonderful summer beach ... I know I'm getting
                  old, nobody nothing can be done about it, but at least with
                  Dr. Kron it's not going so fast.
                </Testimonial>
                <Credit>Philippe C. | Google Maps review</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />

                  <p>
                    Dr. Kron operated on 11/09/2018, I had a tummy tuck, breast
                    lift and liposuction on the same day. How to tell you: apart
                    from his real skills, he is an extraordinary man. He knew
                    from the first appointment to find me the words and convince
                    me with concrete cases that the operation was going to
                    change my life. He will not sell you a dream and if it is
                    not possible he will tell you. Today it's been 4 months and
                    I can tell you that I am another. I regained my confidence
                    and am very happy with my new (physical) life. He regularly
                    follows me from the operation until 1 year postoperatively.
                  </p>

                  <p>
                    Do not really hesitate to get closer to him just once, you
                    will understand what I could feel and live. You know,
                    nowadays it's very important to come across people of trust
                    (which is increasingly rare and even medical ...): Dr. Kron,
                    I do not know if you'll read me, but I wanted to to thank
                    you personally. Know that I will never forget you. I do not
                    have a tattoo on my body but consider being today thanks to
                    the magic of your 2 hands ...
                  </p>
                </Testimonial>
                <Credit>Mme Ch... R. | Tummy tuck and liposuction</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  As the saying goes: a smile costs nothing but it creates a
                  lot. It is with the body and the smile that we communicate
                  more than words, because we are first seen before being heard.
                  So to entrust one's face, when one has decided, to an
                  attentive, expert and rigorous practitioner is the best way.
                  My sincere thanks to Dr. Kron.
                </Testimonial>
                <Credit>Eric Casanova | Plastic surgery</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>Dear Doctor,</p>

                  <p>
                    I did not dare to restrain you this morning after the
                    appointment but I wanted to thank you for your work on my
                    breasts.
                  </p>

                  <p>
                    What you have done really pleases me, it is very very
                    beautiful. I am very happy and amazed at once because the
                    result is far beyond my expectations, I did not imagine how
                    pretty it would be.
                  </p>

                  <p>
                    I also wanted to tell you that I really liked the first
                    consultation appointment. I felt really listened to,
                    respected and absolutely not judged, it was very important
                    for me.
                  </p>

                  <p>
                    I really thank you very much for your comforting presence
                    and kindness before the operation. I was scared, but I was
                    reassured when you came to see me in my room and made marks
                    felt on my breasts.
                  </p>

                  <p>
                    I also thank you for your sweetness. I thought that I would
                    have bruises everywhere after the operation because I have
                    fragile skin and in the end, I have hardly had any and my
                    scars are all fine. I am really amazed by what you did.
                  </p>

                  <p>That's what I wanted to tell you.</p>

                  <p>Thanks millions of times,</p>
                </Testimonial>
                <Credit>Ms. L. | Breast augmentation</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    For the second time, you have successfully operated on my
                    wife, demonstrating that you have not usurped on the one
                    hand, your "Gold Medal of Surgery" and on the other hand,
                    your "Prize of National Academy of Surgery.
                  </p>

                  <p>
                    I especially want to congratulate you, because you honor
                    your Profession by transforming the image of your patients
                    in a very positive way.
                  </p>
                  <p>
                    Having had the honor and the pleasure of meeting you, I
                    noticed that despite the quality of your numerous titles,
                    you remained a very discreet and very human surgeon.
                  </p>
                </Testimonial>
                <Credit>Facelift</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Amazing surgeon, who gives full explanations of how he will
                  proceed. He will have a surgery in 3 weeks, I have all my
                  confidence in him.
                </Testimonial>
                <Credit>Breast Augmentation | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Dr. Kron made a Silhouette Soft threads procedure on me. The
                    result is great. I recommand this doctor, who tries to
                    comply with the desire of the patient, which is rare because
                    in general, plastic surgeon do what they find pleasing...
                    and sometimes it's just ugly.
                  </p>
                </Testimonial>
                <Credit>Silhouette Soft threads | Multiesthetique.fr</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    After consulting several surgeons, my choice will finally be
                    on Dr. Kron met in August 2013 (a friend advised me).
                    Quality of listening, sweetness, pedagogue in his
                    explanations rather than salesman... I felt relaxed and in
                    confidence. Even before my operation, he was able to make me
                    dream...
                  </p>
                  <p>
                    N.B: I found the first visit very satisfying. He gives you
                    your USB key with your 3D profile before / after, with your
                    own profile!
                  </p>
                </Testimonial>
                <Credit>Marie | Review published on Multiesthetique.fr</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  My appointment went very well. Dr. Kron advised very well and
                  made me very confident. I'm very satisfied with this first
                  appointment.
                </Testimonial>
                <Credit>First appointment | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I had a breast augmentation in March
                  2013 by Dr. Kron. I imagined this operation for so many years
                  that I was very anxious about the result. The result was
                  beyond my expectations. Dr. Kron was able to listen to me and
                  to reassure me. He did a remarkable job, with so much
                  naturalness. I finally feel fulfilled, well in my skin and in
                  my body. Many thanks to Dr. Kron.
                </Testimonial>
                <Credit>Aurélie A. | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Dr. Kron, in addition to his high-flying technical skills,
                  takes a real look at his patients. It is his vision almost
                  "artistic" which pushed me to trust his hands. I had a very
                  fast recovery.
                </Testimonial>
                <Credit>Rajeunissement mammaire | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Following the anti-migraine botox ionjections I had last
                  February, I wanted to say thank you. My migraines have
                  decreased considerably. I waited several weeks to be sure that
                  it effective even in the sun. Thanks again. To renew !
                </Testimonial>
                <Credit>Migraine | Multiesthetique.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I have only one regret, not to have
                  done it sooner! The results of this operation are beyond my
                  expectations. I have long complexed on my legs that I dared
                  not show because very thick, heavy and fat. I had a figure
                  shaped like a "Orangina" bottle. I never imagined being able
                  to have this morphology.
                </Testimonial>
                <Credit>Tigh lift | Estheticon.fr</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    I would like to sincerely thank Dr. Kron for the "magical"
                    breast reconstruction that he performed following the
                    mastectomy I had undergone.
                  </p>
                  <p>
                    I am very satisfied with the aesthetic result. I did not
                    expect such a good result. The symmetry is perfect and I
                    felt not pain.
                  </p>
                  <p>
                    I saw a new life having found new breast and no longer
                    having to remove and put back every day the external
                    prosthesis I had to wear.{" "}
                  </p>
                  <p>
                    I especially want to thank the team who welcomed me and the
                    attention you pay to your patients.
                  </p>
                  <p>
                    Thank you very much for helping me close this painfull
                    chapter.
                  </p>
                </Testimonial>
                <Credit>Mariam | Breast Reconstruction</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Mr Kron, thank you for everything. Thank you. Thank you. Thank
                  you.
                </Testimonial>
                <Credit>Facelift and blepharoplasty</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />A big thank to the entire team,
                  especially Dr. Kron. You have been extraordinary. Thanks again
                  for your attention.
                </Testimonial>
                <Credit>Catherine | Liposuction</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Bravo Dr. Kron! Thank you for everything and for this surgery
                  that gives the bald heart to women in their 60s.
                </Testimonial>
                <Credit>Sandrine | Plastic surgery</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>Dr. KRON did a remarkable job on my face.</p>
                  <p>
                    Thank you, because it matters so much to me. I am very
                    grateful.
                  </p>
                  <p>
                    A big thank to all the staff whose kindness and
                    professionalism are outstanding from every point of view.
                  </p>
                </Testimonial>
                <Credit>Jacqueline | Facelift</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />A very big thank you to Dr. Kron
                  whose excellent reputation extends from the Ile de Ré to
                  Brussels! A very big thank you also to Dr. Michon and the
                  entire team. With all my infinite gratitude.
                </Testimonial>
                <Credit>Nathalie | Liposuction</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Thank you very much to Dr. Kron, you are a true artist.
                </Testimonial>
                <Credit>Ewelina | Breast implants</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I had a breast augmentation in March
                  2013 by Dr. Kron. I imagined this operation for so many years
                  that I was very anxious to see the result. And the result is
                  beyond my expectations. Dr. Kron was able to listen to me,
                  reassure me. He did a remarkable job, with so much
                  naturalness. I finally feel fulfilled, well in my skin and in
                  my body. Many thanks to Dr. Kron.
                </Testimonial>
                <Credit>Aurélie A. | Breast Augmentation</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I had a breast augmentation in August
                  2012 and I am very satisfied with the result. My breasts are
                  beautiful, the scars are almost invisible. The result is very
                  natural. I did not feel pain following the procedure. Thanks
                  again to Dr. Kron.
                </Testimonial>
                <Credit>Augmentation mammaire</Credit>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  In addition to extraordinary technical skills, Dr. Kron, takes
                  a real look at his patients. It is his almost artistic vision
                  which pushed me to pass in his hands and thus I found a breast
                  of 20 years with easy postoperative. And a resumption of my
                  activities very fast.
                </Testimonial>
                <Credit>Laura | Breast surgery</Credit>
              </Tile>
            </Li>
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    It has been almost four years since I met Dr. Kron. I asked
                    for a breast reduction and, later, a lipostructure and I am
                    very satisfied. Since then, I feel better in my body and I
                    don't have complex anymore. On top of that, my scars are
                    super discreet.
                  </p>
                  <p>
                    Dr. Kron was very attentive to my desires, available,
                    welcoming and very serious in his work and in the
                    post-operative follow-up he gave me.
                  </p>
                  <Credit>Flora | Breast reduction</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Dr. Kron, thank you for my new life... Thank you for your
                    kindness and your listening. Thank you to all the service of
                    the 4th floor. of the clinic.
                  </p>
                  <Credit>Eve | Breast surgery</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  <p>
                    Dr. Kron operated on me some time ago, he placed gluteal
                    implants and it went very well. I feared the post-surgery
                    effects because I was afraid of pain and, in the end, it was
                    largely bearable because he is very precise in his operative
                    techniques.
                  </p>

                  <p>
                    Today, I'm really happy with the result: my buttocks have
                    more volume and are well rounded. I completely trust this
                    surgeon, he is very professional, he has always been
                    listening to me, has stayed true to my expectations and I
                    thank him sincerely.
                  </p>
                  <Credit>Gluteal implants</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Incomparable skill, deep humanism... A rare and admirable mix.
                  Thank you to Dr. Kron and his team.
                  <Credit>Stéphanie</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Dr. Kron, thank you for your skills, your listening skills,
                  your availability, in a word your professionalism. Thank you.
                  <Credit>Michele</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Thanks to the whole team and to Marie, to Dr Kron, a great
                  professional and knowing how to give all justified trust to
                  the whole team.
                  <Credit>Angélique</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Thank you very much to all the staff at the clinic, especially
                  Dr. Kron, who is very attentive and pleasant.
                  <Credit>Emmanuelle</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Dear Doctor, I feel so light and so happy now! Thank you for
                  making me beautiful and the confidence you have inspired me
                  since our first appointment.
                  <Credit>Marie</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />
                  Fantastic team! Thank you for all the comfort! I leave here
                  with a big smile with a light heart! Special mention to Mary
                  for her incredible energy! Dr. Kron, I feel reshaped! A big
                  thank you for this "artistic" work that is quite exceptional!
                  <Credit>Clotilde</Credit>
                </Testimonial>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <GoQuote {...cssQuote} />I am happy with the result and happy
                  to have trusted you: I feel like I have the eyes that I always
                  wanted to have and I feel so good. The scars fade quickly and
                  I only received compliments. I do not know how to say thank
                  you...
                  <Credit>Edith | Brow lift</Credit>
                </Testimonial>
              </Tile>
            </Li>
          </Masonry>
        </Container>
      </Wrapper>
      <Footer />
    </g.Div>
  </Layout>
);
